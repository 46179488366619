import { refreshMidway } from './midwayTokenRetriever';
import { Auth } from "aws-amplify";

export interface AuthDetails {
	username: string;
	firstName: string;
	lastName: string;
	email: string;
}

export const emptyAuthDetails = {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
}

export const authenticateAmplify = async () => {
	//const accessLevel = function (attributes) {
	//	if (JSON.parse(attributes["custom:isAdmin"])[0] === 'Yes') {
	//		return "admin";
	//	} else if (JSON.parse(attributes["custom:isEdit"])[0] === 'Yes') {
	//		return "edit";
	//	} else if (JSON.parse(attributes["custom:isUser"])[0] === 'Yes') {
	//		return "user";
	//	}
	//	return "";
	//}
	try {
		// eslint-disable-next-line
		const credentials = await refreshMidway();

		const currentUserInfo = await Auth.currentUserInfo();

		try {
			/**
			 * currentAuthenticated can be used to check if a user is logged in when the page is loaded.
			 * It will throw an error if there is no user logged in
			 * https://aws-amplify.github.io/docs/js/authentication#retrieve-current-authenticated-user
			 */
			await Auth.currentAuthenticatedUser(); //const authUser = 
			//const token = authUser.signedInUserSession.idToken.jwtToken
			const { attributes } = currentUserInfo;
			const { identities } = attributes;
			const userId = JSON.parse(identities)[0]["userId"];

			let userInfo: AuthDetails = {
				username: userId,
				firstName: attributes.given_name,
				lastName: attributes.family_name,
				email: attributes.email
			}

			return userInfo;
		} catch (err) {
			await Auth.federatedSignIn({ customProvider: "MidwayOIDC" })
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(`Error occured during authentication ${err}`);
	}
	let userInfo: AuthDetails = emptyAuthDetails;
	return userInfo;
};