// eslint-disable-next-line
import React, { Component } from 'react';

class UnauthorizedUser extends Component {	
	render() {
		return(
			<div id="bindlediv">
				User not authorized
			</div>
		);
	}
}

export default UnauthorizedUser;