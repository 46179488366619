// eslint-disable-next-line
import React, { useState, useEffect } from "react";

//import Row from "@amzn/meridian/row";
//import Box from "@amzn/meridian/box"
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import Pagination from "@amzn/meridian/pagination"

import type { AuthDetails } from "./auth/midwayAuth";
import { Auth } from "aws-amplify";
import { DynamoDB } from "aws-sdk";


const initDDB = async () => {
  var ddb = new DynamoDB({ apiVersion: '2012-08-10' });
  ddb.config.update({
    region: 'us-east-1',
    credentials: await Auth.currentCredentials()
  });
  return ddb;
}

type AlarmProps = {
  userInfo: AuthDetails,
  setPageTitle: Function,
  regionName: string
}

export const AlarmView = (props: AlarmProps) => {

  useEffect(() => {
    props.setPageTitle("Standing Appointment Automation");
    setAlarmList([]);
    setIsDataLoaded(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.regionName]);

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const itemsPerPage = 10;
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const firstVisibleIndex = (pageNumber - 1) * itemsPerPage
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage
  const [alarmList, setAlarmList] = useState<Object[]>([]);

  const [sortColumn] = useState("destination")
  const [sortDirection] = useState("ascending")

  alarmList.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1
    }
    return 0
  })

  useEffect(() => {
    const getAlarms = async () => {
      setIsDataLoaded(false);
      global.AWS.config.region = 'us-east-1';
      const ddb = await initDDB();
      let params = {
        TableName: "auto_appointment_scheduler_alarms_prod",
        FilterExpression: "regionId = :regionName",
        ExpressionAttributeValues: {
          ":regionName": { S: props.regionName },
        },
        ConsistentRead: true
      }
      const data = await ddb.scan(params).promise();

      const resultList = (data.Items ?? []).map(e => {
        return { regionId: e.regionId.S, scheduledAppointmentKey: e.scheduledAppointmentKey.S, carrierId: e.carrierId.S, destination: e.destination.S, slotDay: e.slotDay.S, slotTime: e.slotTime.S, alarmType: e.alarmType.S, alarmDetail: e.alarmDetail.S }
      })

      if (data) {
        setAlarmList(resultList);
        setNumberOfPages(Math.ceil(resultList.length / itemsPerPage));
      }
      setIsDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAlarms();

  }, [props.regionName]);

  return isDataLoaded ?
    <>
      <Column width="100%" alignmentHorizontal="end">
        <Table headerRows={1}
          showDividers={true}
          showStripes={true}
        >
          <TableRow
            highlightOnHover={false}
          >
            <TableCell sortColumn="destination">Site</TableCell>
            <TableCell sortColumn="alarm_type">Alarm Type</TableCell>
            <TableCell sortColumn="slot_day">Slot Day of Week</TableCell>
            <TableCell sortColumn="slot_time">Slot Time of Day</TableCell>
            <TableCell sortColumn="alarm_detail">Alarm Detail</TableCell>
          </TableRow>
          {alarmList.slice(firstVisibleIndex, lastVisibleIndex).map(row => (
            <TableRow key={row["scheduledAppointmentKey"] + "_" + row["alarmType"]}
              highlightOnHover={false}
            >
              <TableCell>{row["destination"]}</TableCell>
              <TableCell>{row["alarmType"]}</TableCell>
              <TableCell>{row["slotDay"]}</TableCell>
              <TableCell>{row["slotTime"]}</TableCell>
              <TableCell>{row["alarmDetail"]}</TableCell>
            </TableRow>
          ))}
        </Table>
        <Pagination
          showSkipArrows={true}
          numberOfPages={numberOfPages}
          onChange={setPageNumber}
          currentPage={pageNumber}
        />
      </Column>
    </>
    : <Loader />

}