import React, { useState, useEffect } from 'react';
import logo from './img/iris-logo_png_2.png';

import './App.css';

import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';

import Loader from "@amzn/meridian/loader";
import AppLayout from '@amzn/meridian/app-layout';
import SidebarLayout from './components/SidebarLayout';
import Masthead, {
  MastheadTitle,
  // MastheadLink,
  //MastheadMenuButton,
} from '@amzn/meridian/masthead';
import Select, { SelectOption } from "@amzn/meridian/select"

// Amplify
import awsExportsDev from './awsExports/dev/aws-exports';
import awsExportsAlpha from './awsExports/alpha/aws-exports';
import awsExportsProd from './awsExports/prod/aws-exports';
import { Amplify, Auth, Analytics } from "aws-amplify";
//import { DynamoDB } from "aws-sdk";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import UnauthorizedUser from './components/error/UnauthorizedUser'
import { authenticateAmplify } from "./components/auth/midwayAuth";
import type { AuthDetails } from "./components/auth/midwayAuth";
import { emptyAuthDetails } from "./components/auth/midwayAuth";
import { AlarmView } from './components/AlarmView';
import { ConfigurationView } from './components/ConfigurationView';
// import { MainAPI } from './MainAPI';

let stage = 'prod';

if (window.location.hostname.includes('dev-dsk') || window.location.hostname.includes('clouddesk')) {
  stage = 'dev';
} else if (window.location.hostname.includes('alpha')) {
  stage = 'alpha';
} else if (window.location.hostname.includes('www')) {
  stage = 'prod';
} else {
  stage = 'prod';
}
const baseUrl = 'iris-auto-appointment-scheduler.gsf.amazon.dev/';

const redirectURLs = {
  dev: 'https://' + window.location.hostname + ':3000/',
  alpha: 'https://alpha.' + baseUrl,
  prod: 'https://www.' + baseUrl
};
const redirectURL = redirectURLs[stage as keyof typeof redirectURLs];

const awsMap = { dev: awsExportsDev, alpha: awsExportsAlpha, prod: awsExportsProd };

const awsExports = awsMap[stage as keyof typeof awsMap];

awsExports.oauth.redirectSignIn = redirectURL;
awsExports.oauth.redirectSignOut = redirectURL;

Amplify.configure(awsExports);
Auth.configure(awsExports);

Analytics.autoTrack('session', {
  enable: true,
  immediate: true,
  provider: "AWSPinpoint"
});

Analytics.autoTrack('pageView', {
  enable: true,
  type: 'SPA',
  immediate: true,
  provider: "AWSPinpoint"
});

Analytics.autoTrack('event', {
  enable: true,
  immediate: true,
  provider: "AWSPinpoint"
})

function App() {
  const [currentPage, setCurrentPage] = useState<string>("Standing Appointment Automation");
  const [userInfo, setUserInfo] = useState<AuthDetails>(emptyAuthDetails);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const hrefList = ["/configuration", "/alarm"];
  const regionNameList = ["NA", "EU"];
  const [regionName, setRegionName] = useState<string>("NA");


  useEffect(() => {
    const getData = async () => {
      const loggedInUser: AuthDetails = await authenticateAmplify();
      setIsLoggedIn(true);
      setUserInfo(loggedInUser);
    }
    getData();
  }, []);

  const Footer = () => (
    <Row
      alignmentVertical="center"
      alignmentHorizontal="center"
    >
      <Text type="h100" color="secondary">brought to you by gsf-iris</Text>
    </Row>
  );

  if (isLoggedIn) {
    return (
      <BrowserRouter>
        <AppLayout headerComponent={Masthead} sidebarComponent={SidebarLayout} footerComponent={Footer}>
          <Masthead>
            <MastheadTitle href="/">
              <Row>
                <img src={logo} alt="" height="40px" width="100px" />
                <Text type="h200" >{currentPage}</Text>
              </Row>
            </MastheadTitle>
            <Column>
              <Row>
                <Text type="h200" >Welcome, {userInfo.username} {"\n"}</Text>
              </Row>
            </Column>
            <Column>
              <Select value={regionName} placeholder="Select Region" onChange={setRegionName} width={100}>
                {
                  regionNameList.map((regionName) =>
                    (<SelectOption key={regionName} value={regionName} label={regionName} />)
                  )
                }
              </Select>
            </Column>
          </Masthead>
          <SidebarLayout displayedLinks={hrefList} />
          <Routes>
            <Route path='/' element={<ConfigurationView regionName={regionName} setPageTitle={setCurrentPage} userInfo={userInfo} />} />
            <Route path='/configuration' element={<ConfigurationView regionName={regionName} setPageTitle={setCurrentPage} userInfo={userInfo} />} />
            <Route path='/alarm' element={<AlarmView regionName={regionName} setPageTitle={setCurrentPage} userInfo={userInfo} />} />
            <Route path='/error/403.html' element={<UnauthorizedUser />} />
          </Routes>
        </AppLayout>
      </BrowserRouter>
    );
  } else {
    return <Loader />
  }
}

export default App;
